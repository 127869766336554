import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'
import { sify } from 'chinese-conv'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import { constants as cons, TABLE_TYPE_DEFAULT_VALUE } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import {
    setQueueTemplate,
    setQueueTemplateTimeSectionValue,
    setQueueTemplateTableTypeValue,
    setQueueTemplateTableTypeColorList,
    setQueueTemplateDefaultTableTypeColorList,
} from '../../actions/queueTemplateAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { QueueTemplateTimeSection } from './QueueTemplateTimeSection'
import { QueueTemplateTimeSectionList } from './QueueTemplateTimeSectionList'
import { QueueTemplateTableType } from './QueueTemplateTableType'
import QueueTemplateValidation from '../../validations/queueTemplateValidation'
import { GlobalDialog } from '../../Component/GlobalDialog'
import {
    getIsSyncMapForRequestTicketTimeFields,
    getIsSyncMapForStartAndEndTimeFields,
} from './queueTemplateUtil'
import { QueueTemplateGroupedSection } from './QueueTemplateGroupedSection'
import { getTableTypeBackgroundColor, getTableTypeForegroundColor } from '../../helper/util'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'
import moment from 'moment'

export const QueueTemplateEdit = () => {
    const dispatch = useDispatch()
    const store = useStore()
    const params = useParams()
    const history = useHistory()
    const [ready, setReady] = useState(false)
    const [clear, setClear] = useState(true)
    const savedQueueTemplate = useSelector((state) => state.queueTemplate.ui.queueTemplate)

    const [restUrlId, setRestUrlId] = useState(null)
    const [queueTemplateInputList, setQueueTemplateInputList] = useState({
        templateCode: '',
        description: '',
        status: '',
    })
    const [customIdNumber, setCustomIdNumber] = useState(0)
    const [selectedType, setSelectedType] = useState('')
    const [dialog, setDialog] = useState({
        open: false,
        dialogActions: [],
    })
    // eslint-disable-next-line no-unused-vars
    const [errorVersion, setErrorVersion] = useState(QueueTemplateValidation.getVersion())
    const [soundVoiceList, setSoundVoiceList] = useState([])

    const resource = 'QueueTemplate'

    const tabs = [
        {
            label: { resource: resource, key: 'info' },
            tabIndex: cons.QUEUE_TEMPLATE_TAB.INFO,
        },
    ]

    const tabContents = [
        {
            tab: (
                <>
                    <div>
                        <TempTextInput
                            inputSetting={{
                                className: 'edit-create-input',
                                variant: cons.STYLE.VARIANT.STANDARD,
                                size: cons.SIZE.SMALL,
                                label: { resource: resource, key: 'templateCode' },
                                value: queueTemplateInputList.templateCode,
                                name: 'templateCode',
                                multiline: true,
                                disabled: true,
                            }}
                            handleChange={onChangeDescription}
                        />
                        <TempTextInput
                            inputSetting={{
                                className: 'edit-create-input',
                                size: cons.SIZE.SMALL,
                                label: { resource: resource, key: 'description' },
                                value: queueTemplateInputList.description,
                                name: 'description',
                                multiline: true,
                                helperTextIcon: true,
                                helperText: QueueTemplateValidation.getErrorMessages('description'),
                            }}
                            handleChange={onChangeDescription}
                        />
                        <TempAutoSelectInput
                            inputSetting={{
                                className: 'edit-create-input',
                                size: cons.SIZE.SMALL,
                                label: { resource: resource, key: 'status' },
                                value: queueTemplateInputList.status,
                                type: cons.TYPE.SELECT,
                                selectValue: cons.CHOICES.QUEUE_TEMPLATE.STATUS,
                                name: 'status',
                                disableClearable: true,
                                isEndOfLine: false,
                            }}
                            handleChange={onChangeStatus}
                        />
                    </div>
                    <Allotment defaultSizes={[100, 200]}>
                        <QueueTemplateTimeSectionList
                            resource={resource}
                            setSelectedType={setSelectedType}
                            setCustomIdNumber={setCustomIdNumber}
                            customIdNumber={customIdNumber}
                        />
                        <div className='queue-template-flex-right'>
                            {selectedType === 'timeSection' ? (
                                <QueueTemplateTimeSection
                                    setCustomIdNumber={setCustomIdNumber}
                                    customIdNumber={customIdNumber}
                                />
                            ) : selectedType === 'tableType' ? (
                                <QueueTemplateTableType
                                    setCustomIdNumber={setCustomIdNumber}
                                    customIdNumber={customIdNumber}
                                    soundVoiceList={soundVoiceList}
                                />
                            ) : selectedType === 'groupedSection' ? (
                                <QueueTemplateGroupedSection />
                            ) : (
                                <></>
                            )}
                        </div>
                    </Allotment>
                </>
            ),
            btn: true,
        },
    ]

    const bottomButtons = [
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'save' },
            onClick: onClickSave,
            className: 'margin-left-16',
            icon: <SaveIcon />,
            disabled: !savedQueueTemplate || savedQueueTemplate.length === 0,
        },
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'clear' },
            disabled: false,
            onClick: onClickClear,
            className: 'margin-left-16',
            icon: <ClearIcon />,
        },
    ]

    function onChangeDescription(name, e) {
        let newQueueTemplateInputList = _.cloneDeep(queueTemplateInputList)
        newQueueTemplateInputList.description = e
        setQueueTemplateInputList(newQueueTemplateInputList)
    }

    function onChangeStatus(name, e) {
        let newQueueTemplateInputList = _.cloneDeep(queueTemplateInputList)
        newQueueTemplateInputList.status = e
        setQueueTemplateInputList(newQueueTemplateInputList)
    }

    const onClickBackToEdit = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        onClickClear()
    }

    const onClickBackToList = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        history.push(
            '/' +
                cons.PATH.SHOP.SYSTEM_GROUP_RESTAURANT +
                '/' +
                restUrlId +
                '/' +
                cons.RESTAURANT_TAB.QUEUE +
                '/' +
                cons.RESTAURANT_QUEUE_SUB_TAB.QUEUE_TEMPLATE
        )
    }

    function onClickSave() {
        QueueTemplateValidation.validateTemplate(
            savedQueueTemplate,
            queueTemplateInputList.description
        )
        if (QueueTemplateValidation.isValid()) {
            let clonedSavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
            let newTableTypeList = []

            clonedSavedQueueTemplate.forEach((timeSection) => {
                let clonedTableTypeList = _.cloneDeep(timeSection.tableTypeList, timeSection)
                let newTableTypeListIdx = clonedTableTypeList.length - 1
                delete timeSection.id
                delete timeSection.status
                delete timeSection.tableTypeList
                delete timeSection.endTime
                delete timeSection.startTime
                delete timeSection.kioskRequestTicketStartTime
                delete timeSection.kioskRequestTicketEndTime
                delete timeSection.mobileRequestTicketStartTime
                delete timeSection.mobileRequestTicketEndTime
                delete timeSection.timeSectionDescription
                clonedTableTypeList.forEach((clonedTableType) =>
                    newTableTypeList.push(
                        Object.assign(
                            { ...clonedTableType, displaySequence: newTableTypeListIdx-- * 10 },
                            timeSection
                        )
                    )
                )
            })

            put(
                cons.PATH.SHOP.QUEUE.QUEUE_TEMPLATE + '/' + params.id,
                {
                    restUrlId: restUrlId,
                    description: queueTemplateInputList.description,
                    status: queueTemplateInputList.status,
                    timeSectionList: newTableTypeList,
                },
                () => {
                    setDialog({
                        open: true,
                        onClose: onClickBackToEdit,
                        dialogContentText: { key: 'editSuccess' },
                        maxWidth: 'md',
                        dialogActions: [
                            {
                                label: { key: 'backEdit' },
                                onClick: onClickBackToEdit,
                                icon: <ArrowBackIcon />,
                            },
                            {
                                label: { key: 'backList' },
                                onClick: onClickBackToList,
                                icon: <LowPriorityIcon />,
                            },
                        ],
                    })
                    setClear(false)
                },
                undefined,
                store
            )
            QueueTemplateValidation.resetErrorMessages()
        } else {
            setErrorVersion(QueueTemplateValidation.getVersion())
        }
    }

    function onClickClear() {
        setClear(true)
    }

    useEffect(() => {
        dispatch(setQueueTemplate(cons.QUEUE_TEMPLATE_INPUT_LIST))
        QueueTemplateValidation.resetErrorMessages()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // TODO: useEffect part need refactor to be more readable, naming of variables and functions to be more descriptive
    useEffect(() => {
        if (clear === true) {
            getOne(
                cons.PATH.SHOP.QUEUE.QUEUE_TEMPLATE + '/' + params.id,
                undefined,
                (payload) => {
                    setQueueTemplateInputList({
                        templateCode: payload.templateCode,
                        description: payload.description,
                        status: payload.status,
                    })
                    setRestUrlId(payload.restUrlId)
                    dispatch(setQueueTemplateTableTypeColorList(payload.tableTypeColorConfigList))
                    dispatch(
                        setQueueTemplateDefaultTableTypeColorList(
                            payload.defaultTableTypeColorConfigList
                        )
                    )

                    let originalTimeSectionList = _.cloneDeep(payload.timeSectionList)
                    let newTimeSectionList = []

                    /* A map that store all table types
          in the same time section: Boolean checked whether
          the time related fields are same as the parent
          time section ↓↓↓*/
                    const isSyncMapForStartAndEndTimeFields =
                        getIsSyncMapForStartAndEndTimeFields(originalTimeSectionList)
                    const isSyncMapForRequestTicketTimeFields =
                        getIsSyncMapForRequestTicketTimeFields(originalTimeSectionList)

                    originalTimeSectionList.forEach((originalTableType) => {
                        if (
                            newTimeSectionList.length > 0 &&
                            newTimeSectionList
                                .map((t) => {
                                    return t.timeSectionId
                                })
                                .includes(originalTableType.timeSectionId)
                        ) {
                            newTimeSectionList.forEach((newTableType) => {
                                if (
                                    newTableType.timeSectionId === originalTableType.timeSectionId
                                ) {
                                    if (
                                        (!originalTableType.timeSectionTcLabel &&
                                            !originalTableType.timeSectionScLabel) ||
                                        (originalTableType.timeSectionTcLabel &&
                                            sify(originalTableType.timeSectionTcLabel) ===
                                                originalTableType.timeSectionScLabel)
                                    ) {
                                        originalTableType.disableLabel = true
                                    } else {
                                        originalTableType.disableLabel = false
                                    }

                                    if (
                                        (!originalTableType.tableTypeMobileTcLabel &&
                                            !originalTableType.tableTypeMobileScLabel) ||
                                        (originalTableType.tableTypeMobileTcLabel &&
                                            sify(originalTableType.tableTypeMobileTcLabel) ===
                                                originalTableType.tableTypeMobileScLabel)
                                    ) {
                                        originalTableType.disableMobileLabel = true
                                    } else {
                                        originalTableType.disableMobileLabel = false
                                    }

                                    if (
                                        (!originalTableType.groupedSectionTcLabel &&
                                            !originalTableType.groupedSectionScLabel) ||
                                        (originalTableType.groupedSectionTcLabel &&
                                            sify(originalTableType.groupedSectionTcLabel) ===
                                                originalTableType.groupedSectionScLabel)
                                    ) {
                                        originalTableType.disableGroupedSection = true
                                    } else {
                                        originalTableType.disableGroupedSection = false
                                    }

                                    if (
                                        (!originalTableType.groupedSectionTcDescription &&
                                            !originalTableType.groupedSectionScDescription) ||
                                        (originalTableType.groupedSectionTcDescription &&
                                            sify(originalTableType.groupedSectionTcDescription) ===
                                                originalTableType.groupedSectionScDescription)
                                    ) {
                                        originalTableType.disableGroupedSectionDescription = true
                                    } else {
                                        originalTableType.disableGroupedSectionDescription = false
                                    }

                                    originalTableType.customId = originalTableType.id
                                    originalTableType.timeSectionCustomId = newTableType.id

                                    if (
                                        (!originalTableType.tableTypeTicketTcLabel &&
                                            !originalTableType.tableTypeTicketScLabel) ||
                                        (originalTableType.tableTypeTicketTcLabel &&
                                            sify(originalTableType.tableTypeTicketTcLabel) ===
                                                originalTableType.tableTypeTicketScLabel)
                                    ) {
                                        originalTableType.disableTicketLabel = true
                                    } else {
                                        originalTableType.disableTicketLabel = false
                                    }

                                    originalTableType.displayTableTypeForegroundColor =
                                        getTableTypeForegroundColor(
                                            originalTableType.tableTypeForegroundColor,
                                            newTableType.tableTypeList?.length
                                                ? newTableType.tableTypeList.length
                                                : 0,
                                            payload.tableTypeColorConfigList ||
                                                payload.defaultTableTypeColorConfigList
                                        )

                                    originalTableType.displayTableTypeBackgroundColor =
                                        getTableTypeBackgroundColor(
                                            originalTableType.tableTypeBackgroundColor,
                                            newTableType.tableTypeList?.length
                                                ? newTableType.tableTypeList.length
                                                : 0,
                                            +payload.tableTypeColorConfigList ||
                                                payload.defaultTableTypeColorConfigList
                                        )

                                    if (typeof originalTableType.cutOffMinute === 'number') {
                                        const today = moment()
                                        const split = originalTableType.startTime.split(':')

                                        const startTimeMoment = today.set({
                                            hour: split[0],
                                            minute: split[1],
                                            second: split[2],
                                        })

                                        originalTableType.cutOffTime = startTimeMoment
                                            .subtract(originalTableType.cutOffMinute, 'minutes')
                                            .format('HH:mm:ss')
                                    } else {
                                        originalTableType.cutOffTime = null
                                    }

                                    newTableType.tableTypeList.push(originalTableType)
                                }
                            })
                        } else {
                            if (
                                (!originalTableType.timeSectionTcLabel &&
                                    !originalTableType.timeSectionScLabel) ||
                                (originalTableType.timeSectionTcLabel &&
                                    sify(originalTableType.timeSectionTcLabel) ===
                                        originalTableType.timeSectionScLabel)
                            ) {
                                originalTableType.disableLabel = true
                            } else {
                                originalTableType.disableLabel = false
                            }

                            if (
                                (!originalTableType.tableTypeMobileTcLabel &&
                                    !originalTableType.tableTypeMobileScLabel) ||
                                (originalTableType.tableTypeMobileTcLabel &&
                                    sify(originalTableType.tableTypeMobileTcLabel) ===
                                        originalTableType.tableTypeMobileScLabel)
                            ) {
                                originalTableType.disableMobileLabel = true
                            } else {
                                originalTableType.disableMobileLabel = false
                            }

                            if (
                                (!originalTableType.groupedSectionTcLabel &&
                                    !originalTableType.groupedSectionScLabel) ||
                                (originalTableType.groupedSectionTcLabel &&
                                    sify(originalTableType.groupedSectionTcLabel) ===
                                        originalTableType.groupedSectionScLabel)
                            ) {
                                originalTableType.disableGroupedSection = true
                            } else {
                                originalTableType.disableGroupedSection = false
                            }

                            if (
                                (!originalTableType.groupedSectionTcDescription &&
                                    !originalTableType.groupedSectionScDescription) ||
                                (originalTableType.groupedSectionTcDescription &&
                                    sify(originalTableType.groupedSectionTcDescription) ===
                                        originalTableType.groupedSectionScDescription)
                            ) {
                                originalTableType.disableGroupedSectionDescription = true
                            } else {
                                originalTableType.disableGroupedSectionDescription = false
                            }

                            originalTableType.customId = originalTableType.id
                            originalTableType.timeSectionCustomId = originalTableType.id

                            if (
                                (!originalTableType.tableTypeTicketTcLabel &&
                                    !originalTableType.tableTypeTicketScLabel) ||
                                (originalTableType.tableTypeTicketTcLabel &&
                                    sify(originalTableType.tableTypeTicketTcLabel) ===
                                        originalTableType.tableTypeTicketScLabel)
                            ) {
                                originalTableType.disableTicketLabel = true
                            } else {
                                originalTableType.disableTicketLabel = false
                            }

                            originalTableType.displayTableTypeForegroundColor =
                                getTableTypeForegroundColor(
                                    originalTableType.tableTypeForegroundColor,
                                    0,
                                    payload.tableTypeColorConfigList ||
                                        payload.defaultTableTypeColorConfigList
                                )

                            originalTableType.displayTableTypeBackgroundColor =
                                getTableTypeBackgroundColor(
                                    originalTableType.tableTypeBackgroundColor,
                                    0,
                                    payload.tableTypeColorConfigList ||
                                        payload.defaultTableTypeColorConfigList
                                )

                            if (typeof originalTableType.cutOffMinute === 'number') {
                                const today = moment()
                                const split = originalTableType.startTime.split(':')

                                const startTimeMoment = today.set({
                                    hour: split[0],
                                    minute: split[1],
                                    second: split[2],
                                })

                                originalTableType.cutOffTime = startTimeMoment
                                    .subtract(originalTableType.cutOffMinute, 'minutes')
                                    .format('HH:mm:ss')
                            } else {
                                originalTableType.cutOffTime = null
                            }

                            const isSyncStartAndEndTimeFields =
                                isSyncMapForStartAndEndTimeFields.get(
                                    originalTableType.timeSectionId
                                )
                            const isSyncRequestTicketTimeFields =
                                isSyncMapForRequestTicketTimeFields.get(
                                    originalTableType.timeSectionId
                                )

                            newTimeSectionList.push({
                                id: originalTableType.id,
                                customId: originalTableType.id,
                                templateCode: originalTableType.templateCode,
                                timeSectionId: originalTableType.timeSectionId,
                                timeSectionTcLabel: originalTableType.timeSectionTcLabel,
                                timeSectionEnLabel: originalTableType.timeSectionEnLabel,
                                timeSectionScLabel: originalTableType.timeSectionScLabel,
                                disableLabel: originalTableType.disableLabel,
                                isSyncTimeFieldsWithAllTableTypes:
                                    isSyncStartAndEndTimeFields || false,
                                isSyncRequestTicketTimeFieldsWithAllTableTypes:
                                    isSyncRequestTicketTimeFields || false,
                                startTime: isSyncStartAndEndTimeFields
                                    ? originalTableType.startTime
                                    : null,
                                endTime: isSyncStartAndEndTimeFields
                                    ? originalTableType.endTime
                                    : null,
                                timeSectionDescription: isSyncStartAndEndTimeFields
                                    ? originalTableType.timeSectionDescription
                                    : '',
                                kioskRequestTicketStartTime: isSyncRequestTicketTimeFields
                                    ? originalTableType.kioskRequestTicketStartTime
                                    : null,
                                kioskRequestTicketEndTime: isSyncRequestTicketTimeFields
                                    ? originalTableType.kioskRequestTicketEndTime
                                    : null,
                                mobileRequestTicketStartTime: isSyncRequestTicketTimeFields
                                    ? originalTableType.mobileRequestTicketStartTime
                                    : null,
                                mobileRequestTicketEndTime: isSyncRequestTicketTimeFields
                                    ? originalTableType.mobileRequestTicketEndTime
                                    : null,
                                status: 'A',
                                tableTypeList: [originalTableType],
                            })
                        }
                    })

                    dispatch(setQueueTemplate(newTimeSectionList))
                    dispatch(setQueueTemplateTimeSectionValue(cons.TIME_SECTION_DEFAULT_VALUE))
                    dispatch(setQueueTemplateTableTypeValue(TABLE_TYPE_DEFAULT_VALUE))
                    setSelectedType('')

                    getOne(
                        cons.PATH.MERCHANT.SOUND_INFO_VOICE,
                        undefined,
                        (payload) => {
                            setSoundVoiceList(
                                payload.map((soundVoice) => ({
                                    name: soundVoice.name,
                                    value: soundVoice.code,
                                }))
                            )
                        },
                        undefined,
                        store
                    )

                    setReady(true)
                    setClear(false)
                },
                undefined,
                store
            )
        }
    }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

    const { getRestaurantIdAndName } = useRestaurant(restUrlId)
    const mapCode = getRestaurantIdAndName()

    // useEffect(() => {
    //   console.log('savedQueueTemplate', savedQueueTemplate)
    // }, [savedQueueTemplate])

    return (
        ready && (
            <>
                <EditCreateDisplay
                    code={savedQueueTemplate && savedQueueTemplate.id ? savedQueueTemplate.id : ''}
                    resource={resource}
                    tabs={tabs}
                    content={tabContents}
                    bottomBtns={bottomButtons}
                    mapName={{ resource: 'restaurant', key: 'restUrlId' }}
                    mapCode={mapCode}
                    display='edit'
                />
                {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
            </>
        )
    )
}
