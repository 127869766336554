import { useEffect, useState } from 'react'
import '../../../styles/getDistrictId.scss'
import GridContainer from '../field/GridContainer'
import { AreaField } from './AreaField'
import { CityField } from './CityField'
import { DistrictField } from './DistrictField'
import { RegionField } from './RegionField'

export const LOCATION_FIELDS_KEY = {
    region: 'region',
    city: 'city',
    area: 'area',
    district: 'district',
}

export default function LocationFields({
    savedData,
    error,
    setDistrict,
    setCity,
    setRegion,
    setArea,
    fields = Object.keys(LOCATION_FIELDS_KEY),
}) {
    const f = new Set(fields)
    const [fieldsSet, setFieldsSet] = useState(new Set(fields))

    // console.log('savedData', savedData)

    useEffect(() => {
        if (f.has(LOCATION_FIELDS_KEY.region) && f.has(LOCATION_FIELDS_KEY.city)) {
            const newSet = new Set(fieldsSet)

            if (savedData?.regionCode) {
                newSet.add(LOCATION_FIELDS_KEY.city)
            } else {
                newSet.delete(LOCATION_FIELDS_KEY.city)
            }

            setFieldsSet(newSet)
        }

        if (f.has(LOCATION_FIELDS_KEY.city) && f.has(LOCATION_FIELDS_KEY.area)) {
            const newSet = new Set(fieldsSet)

            if (savedData?.cityCode) {
                newSet.add(LOCATION_FIELDS_KEY.area)
            } else {
                newSet.delete(LOCATION_FIELDS_KEY.area)
            }

            setFieldsSet(newSet)
        }

        if (f.has(LOCATION_FIELDS_KEY.area) && f.has(LOCATION_FIELDS_KEY.district)) {
            setFieldsSet((pre) => {
                const newSet = new Set([...pre])

                if (savedData.areaId) {
                    newSet.add(LOCATION_FIELDS_KEY.district)
                } else {
                    newSet.delete(LOCATION_FIELDS_KEY.district)
                }

                return newSet
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedData])

    return (
        <GridContainer>
            {fieldsSet.has(LOCATION_FIELDS_KEY.region) && (
                <RegionField
                    setRegion={setRegion}
                    error={error?.regionCode}
                    value={savedData.regionName}
                />
            )}
            {fieldsSet.has(LOCATION_FIELDS_KEY.city) && (
                <CityField
                    setCity={setCity}
                    error={error?.cityCode}
                    value={savedData.cityName}
                    filterRegionCode={savedData?.regionCode || ''}
                />
            )}
            {fieldsSet.has(LOCATION_FIELDS_KEY.area) && (
                <AreaField
                    setArea={setArea}
                    value={savedData.areaName}
                    error={error?.areaId}
                />
            )}
            {fieldsSet.has(LOCATION_FIELDS_KEY.district) && (
                <DistrictField
                    setDistrict={setDistrict}
                    value={savedData.districtName}
                    filterCityCode={savedData?.cityCode || ''}
                />
            )}
        </GridContainer>
    )
}
