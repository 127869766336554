import { useParams } from 'react-router-dom'
import { constants as cons } from '../../Constants/constants'
import { useSelector } from 'react-redux'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { SubTabEditCreateDisplay } from '../../Component/EditCreateDisplay/SubTabEditCreateDisplay'
import { BannerList } from '../Banner/BannerList'
import { AdminDeviceList } from '../AdminDevice/AdminDeviceList'
import { DisplayMessageList } from '../CampaignDescription/DisplayMessageList'
import { TnCList } from '../TnC/TnCList'
import { StatusEdit } from '../RestaurantStatus/Status'
import { RestaurantInfo } from './RestaurantInfo'
import { RestaurantMediaList } from '../RestaurantMedia/RestaurantMediaList'
import { QueueConfigEdit } from '../RestaurantQueueConfig/RestaurantQueueConfig'
import { PrintConfigEdit } from '../PrintConfig/PrintConfig'
import { QueueTemplateList } from '../QueueTemplate/QueueTemplateList'
import { QueueTempateMapListWrapper } from '../QueueTemplateMap/QueueTempateMapListWrapper'
import { ReservationConfigEdit } from '../ReservationConfig/ReservationConfig'
// import { ReservationTemplateList } from "../ReservationTemplate/ReservationTemplateList";
// import { ReservationTemplateMapList } from "../ReservationTemplateMap/ReservationTemplateMapList";
// import { ChargeDateList } from "../ChargeDate/ChargeDateList";
import { MerchantConfigEdit } from '../MerchantConfig/MerchantConfig'
import { SelectTagConfigEdit } from '../SelectTagConfig/SelectTagConfig'
import { PaymentList } from '../RestaurantPayment/RestaurantPaymentList'
import { RestaurantBlackoutList } from '../RestaurantBlackout/RestaurantBlackoutList'
import { RestaurantSlideshowList } from '../RestaurantSlideshow/RestaurantSlideshowList'
import { QueueRejectReasonList } from '../../Component/QueueRejectReason/QueueRejectReasonList'
import { ReservationRejectReasonList } from '../ReservationRejectReason/ReservationRejectReasonList'
import GenericQueueProfileList from '../GenericQueueProfile/GenericQueueProfileList'
import CounterList from '../Counter/CounterList'
import CounterProfileList from '../CounterProfile/CounterProfileList'
import { StaffList } from '../Staff/StaffList'
import { MerchantDeviceList } from '../MerchantDevice/MerchantDeviceList'
import { useEffect, useState } from 'react'
import { PickupList } from '../Pickup/PickupList'
import QueueDashboard from '../Dashboard/QueueDashboard'
import ReportConfig from '../Report/ReportConfig'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export const RestaurantEdit = () => {
    const resource = 'restaurant'
    const params = useParams()
    const savedRestaurant = useSelector((state) => state.restaurant.ui.restaurant)
    const [mapCode, setMapCode] = useState()
    const { getRestaurantName } = useRestaurant()

    const tabs = [
        {
            label: { resource: resource, key: 'info' },
            tabIndex: cons.RESTAURANT_TAB.INFO,
        },
        {
            label: { resource: resource, key: 'queue' },
            tabIndex: cons.RESTAURANT_TAB.QUEUE,
        },
        {
            label: { resource: resource, key: 'reservation' },
            tabIndex: cons.RESTAURANT_TAB.RESERVATION,
        },
        {
            label: { resource: resource, key: 'pickup' },
            tabIndex: cons.RESTAURANT_TAB.PICKUP,
        },
        {
            label: { resource: resource, key: 'merchant' },
            tabIndex: cons.RESTAURANT_TAB.MERCHANT,
        },
        {
            label: { resource: resource, key: 'media' },
            tabIndex: cons.RESTAURANT_TAB.MEDIA,
        },
        {
            label: { resource: resource, key: 'product' },
            tabIndex: cons.RESTAURANT_TAB.PRODUCT,
        },
        {
            label: { resource: resource, key: 'report' },
            tabIndex: cons.RESTAURANT_TAB.REPORT,
        },
    ]

    const subTabInfoTitleList = [
        {
            label: { resource: resource, key: 'info' },
            tabIndex: cons.RESTAURANT_SUB_TAB.INFO,
        },
        {
            label: { resource: 'Status', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_SUB_TAB.STATUS,
        },
        {
            label: { resource: 'AdminDevice', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_SUB_TAB.ADMIN_DEVICE,
        },
        {
            label: { resource: resource, key: 'campaignDescription' },
            tabIndex: cons.RESTAURANT_SUB_TAB.CAMPAIGN_DESCRIPTION,
        },
        {
            label: { resource: resource, key: 'tnc' },
            tabIndex: cons.RESTAURANT_SUB_TAB.TNC,
        },
        {
            label: { resource: resource, key: 'select_tag' },
            tabIndex: cons.RESTAURANT_SUB_TAB.SELECT_TAG,
        },
        {
            label: { resource: resource, key: 'payment' },
            tabIndex: cons.RESTAURANT_SUB_TAB.PAYMENT,
        },
    ]

    const subTabQueueTitleList = [
        {
            label: { resource: 'QueueConfig', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_QUEUE_SUB_TAB.QUEUE_CONFIG,
        },
        {
            label: { resource: 'PrintConfig', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_QUEUE_SUB_TAB.PRINT_CONFIG,
        },
        {
            label: { resource: 'QueueTemplate', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_QUEUE_SUB_TAB.QUEUE_TEMPLATE,
        },
        {
            label: { resource: 'QueueTemplateMap', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_QUEUE_SUB_TAB.QUEUE_TEMPLATE,
        },
        {
            label: { resource: 'QueueRejectReason', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_QUEUE_SUB_TAB.QUEUE_REJECT_REASON,
        },
        {
            label: { resource: resource, key: 'dashboard' },
            tabIndex: cons.RESTAURANT_QUEUE_SUB_TAB.DASHBOARD,
        },
    ]

    const subTabReservationTitleList = [
        {
            label: { resource: 'ReservationConfig', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_RESERVATION_SUB_TAB.RESERVATION_CONFIG,
        },
        // {
        //   label: { resource: "ReservationTemplate", key: "mainTitle" },
        //   tabIndex: constants.RESTAURANT_RESERVATION_SUB_TAB.RESERVATION_TEMPLATE
        // },
        // {
        //   label: { resource: "ReservationTemplateMap", key: "mainTitle" },
        //   tabIndex: constants.RESTAURANT_RESERVATION_SUB_TAB.RESERVATION_TEMPLATE_MAP
        // },
        // {
        //   label: { resource: "ChargeDate", key: "mainTitle" },
        //   tabIndex: constants.RESTAURANT_RESERVATION_SUB_TAB.CHARGE_DATE
        // },
        {
            label: { resource: 'ReservationRejectReason', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_RESERVATION_SUB_TAB.RESERVATON_REJECT_REASON,
        },
    ]

    const subTabPickupTitleList = [
        {
            label: { resource: 'Pickup', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_PICKUP_SUB_TAB.PICKUP_CONFIG,
        },
    ]

    const subTabMerchantTitleList = [
        {
            label: { resource: 'MerchantConfig', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MERCHANT_SUB_TAB.MERCHANT_CONFIG,
        },
        {
            label: { resource: 'MerchantDevice', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MERCHANT_SUB_TAB.MERCHANT_CONFIG,
        },
        {
            label: { resource: 'Counter', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MERCHANT_SUB_TAB.COUNTER,
        },
        {
            label: { resource: 'Staff', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MERCHANT_SUB_TAB.STAFF,
        },
        {
            label: { resource: 'QueueProfile', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MERCHANT_SUB_TAB.QUEUE_PROFILE,
        },
        {
            label: { resource: 'QueueTransferProfile', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MERCHANT_SUB_TAB.QUEUE_TRANSFER_PROFILE,
        },
        {
            label: { resource: 'CounterProfile', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MERCHANT_SUB_TAB.COUNTER_PROFILE,
        },
    ]

    const subTabMediaTitleList = [
        {
            label: { resource: 'RestaurantMedia', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MEDIA_SUB_TAB.MEDIA,
        },
        {
            label: { resource: resource, key: 'retaurantBannerList' },
            tabIndex: cons.RESTAURANT_MEDIA_SUB_TAB.BANNER_LIST,
        },
        {
            label: { resource: 'RestaurantSlideshow', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_MEDIA_SUB_TAB.SLIDESHOW,
        },
    ]

    const subTabProductTitleList = [
        {
            label: { resource: 'RestaurantBlackout', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_PRODUCT_SUB_TAB.BLACKOUT,
        },
    ]

    const subTabReportTitleList = [
        {
            label: { resource: 'reportConfig', key: 'reportConfig' },
            tabIndex: cons.RESTAURANT_REPORT_SUB_TUB.REPORT_CONFIG,
        },
    ]

    const subTabSelectTagTitleList = [
        {
            label: { resource: 'SelectTag', key: 'mainTitle' },
            tabIndex: cons.RESTAURANT_SELECT_TAG_SUB_TAB.SELECT_TAG_CONFIG,
        },
    ]

    const subTabInfoContentList = [
        {
            tab: <RestaurantInfo />,
            btn: false,
        },
        {
            tab: <StatusEdit />,
            btn: false,
        },
        {
            tab: <AdminDeviceList editBtn={true} createBtn={true} />,
            btn: false,
        },
        {
            tab: <DisplayMessageList editBtn={true} createBtn={true} />,
            btn: false,
        },
        {
            tab: <TnCList editBtn={true} createBtn={true} />,
            btn: false,
        },
        {
            tab: <SelectTagConfigEdit />,
            btn: false,
        },
        {
            tab: <PaymentList />,
            btn: false,
        },
    ]

    const subTabQueueContentList = [
        {
            tab: <QueueConfigEdit />,
            btn: false,
        },
        {
            tab: <PrintConfigEdit />,
            btn: false,
        },
        {
            tab: <QueueTemplateList editBtn={true} createBtn={true} />,
            btn: false,
        },
        {
            tab: <QueueTempateMapListWrapper editBtn={true} createBtn={true} />,
            btn: false,
        },
        {
            tab: <QueueRejectReasonList editBtn={true} createBtn={true} />,
            btn: false,
        },
        {
            tab: <QueueDashboard />,
            btn: false,
        },
    ]

    const subTabReservationContentList = [
        {
            tab: <ReservationConfigEdit />,
            btn: false,
        },
        // {
        //   tab: <ReservationTemplateList
        //     editBtn={true}
        //     createBtn={true} />,
        //   btn: false
        // },
        // {
        //   tab: <ReservationTemplateMapList
        //     editBtn={true}
        //     createBtn={true} />,
        //   btn: false
        // },
        // {
        //   tab: <ChargeDateList
        //     editBtn={true}
        //     createBtn={true} />,
        //   btn: false
        // },
        {
            tab: <ReservationRejectReasonList />,
            btn: false,
        },
    ]

    const subTabPickupContentList = [
        {
            tab: <PickupList />,
            btn: false,
        },
    ]

    const subTabMerchantContentList = [
        {
            tab: <MerchantConfigEdit />,
        },
        {
            tab: <MerchantDeviceList />,
        },
        {
            tab: <CounterList />,
        },
        {
            tab: <StaffList />,
        },
        {
            tab: <GenericQueueProfileList resource='QueueProfile' />,
        },
        {
            tab: <GenericQueueProfileList resource='QueueTransferProfile' />,
        },
        {
            tab: <CounterProfileList />,
        },
    ]

    const subTabMediaContentList = [
        {
            tab: <RestaurantMediaList changeUrlQueryString={true} />,
            btn: false,
        },
        {
            tab: (
                <BannerList
                    changeUrlQueryString={true}
                    newListMainTitle={{ resource: resource, key: 'retaurantBannerList' }}
                    newFilter={{ id: params.id }}
                    idParam={params.id}
                    newPath={cons.PATH.SHOP.RESTAURANT_BANNER}
                    newListTitles={[
                        {
                            key: cons.LIST_DISPLAY_TYPE.IMAGE,
                            value: 'enImage',
                            sort: false,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.IMAGE,
                            value: 'tcImage',
                            sort: false,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.IMAGE,
                            value: 'scImage',
                            sort: false,
                        },
                        // {
                        //   key: constants.LIST_DISPLAY_TYPE.STRING,
                        //   value: "name",
                        //   sort: false
                        // },
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'actionType',
                            sort: true,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.DATE_TIME,
                            value: 'startDisplayDate',
                            sort: false,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.DATE_TIME,
                            value: 'endDisplayDate',
                            sort: false,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'sequence',
                            sort: true,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'status',
                            sort: true,
                        },
                    ]}
                    editBtn={true}
                    createBtn={true}
                />
            ),
            btn: false,
        },
        {
            tab: <RestaurantSlideshowList changeUrlQueryString={true} />,
            btn: false,
        },
    ]

    const subTabSelectTagContentList = [
        {
            tab: <SelectTagConfigEdit />,
            btn: false,
        },
    ]

    const subTabProductContentList = [
        {
            tab: <RestaurantBlackoutList />,
            btn: false,
        },
    ]

    const subTabReportContentList = [
        {
            tab: <ReportConfig />,
            btn: false,
        },
    ]

    const content = [
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabInfoTitleList}
                    content={subTabInfoContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabQueueTitleList}
                    content={subTabQueueContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabReservationTitleList}
                    content={subTabReservationContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabPickupTitleList}
                    content={subTabPickupContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabMerchantTitleList}
                    content={subTabMerchantContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabMediaTitleList}
                    content={subTabMediaContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabProductTitleList}
                    content={subTabProductContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabReportTitleList}
                    content={subTabReportContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
        {
            tab: (
                <SubTabEditCreateDisplay
                    code={''}
                    resource={resource}
                    tabs={subTabSelectTagTitleList}
                    content={subTabSelectTagContentList}
                    saveDialog={{
                        open: false,
                        dialogActions: [],
                    }}
                    display='none'
                />
            ),
            btn: false,
        },
    ]

    useEffect(() => {
        setMapCode(savedRestaurant.restUrlId ? `${params.id} - ${getRestaurantName()}` : params.id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedRestaurant])

    return (
        <EditCreateDisplay
            code={mapCode}
            resource={resource}
            tabs={tabs}
            content={content}
            saveDialog={{
                open: false,
                dialogActions: [],
            }}
            display='edit'
        />
    )
}
