import { useSelector, useDispatch } from 'react-redux'
import { constants as cons } from '../../Constants/constants'
import { getOne } from '../../helper/baseFetch'
import { setRestaurant } from '../../actions'
import store from '../../store'
import { useEffect } from 'react'

export default function useRestaurant(restUrlId) {
    const savedRestaurant = useSelector((state) => state.restaurant.ui.restaurant)
    const lang = useSelector((state) => state.lang.ui.lang)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!restUrlId) {
            return
        }

        if (savedRestaurant.restUrlId && savedRestaurant.restUrlId === restUrlId) {
            return
        }
        
        getOne(
            `${cons.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}/${restUrlId}`,
            undefined,
            (payload) => {
                dispatch(setRestaurant(payload))
            },
            undefined,
            store
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restUrlId])

    const getRestaurantName = () => {
        switch (lang) {
            case cons.LANG.EN:
                return savedRestaurant?.enName || ''
            case cons.LANG.SC:
                return savedRestaurant?.scName || ''
            default:
                return savedRestaurant?.tcName || ''
        }
    }

    const getRestUrlId = () => {
        return savedRestaurant?.restUrlId || ''
    }

    const getRestaurantIdAndName = () => {
        const restUrlId = getRestUrlId()
        const restName = getRestaurantName()

        return `${restUrlId || ''} - ${restName}`
    }

    return { getRestaurantName, getRestUrlId, getRestaurantIdAndName }
}
